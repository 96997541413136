import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <div
          style={{
              display: 'flex',
              background: 'black',
              justifyContent: 'center',
              height: '100vh',  // 使内容高度充满视口
          }}
      >
          <div
              style={{
                  width: '100%',
                  background: 'black',
                  maxWidth: '414px',   // 这里设置为较大的手机宽度，如 iPhone 12 Pro 的 414px
                  minHeight: '100vh',
              }}
          >
              <div style={{backgroundImage: "url(./dark-gold.jpg)", backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "100vh"}}>
                  <App />
              </div>
          </div>
      </div>
  </React.StrictMode>
);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
