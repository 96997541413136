import React, { useState } from 'react';
import './slot-machine.css';
import Swal from "sweetalert2"; // 引入CSS文件

const SlotMachine = ({username, email, onFinished}) => {
    const [reel1, setReel1] = useState(1);
    const [reel2, setReel2] = useState(1);
    const [reel3, setReel3] = useState(1);

    const spinReel = (setReel, final) => {
        return new Promise((resolve) => {
            let duration = Math.random() * 2000 + 1000; // 随机持续时间
            let interval = setInterval(() => {
                let randomPosition = Math.floor((Math.random() * 30) + 1); // 生成1-30之间的随机数字
                setReel(randomPosition);
            }, 100);

            setTimeout(() => {
                clearInterval(interval);
                setReel(final);
                resolve();
            }, duration);
        });
    };

    const handleSpin = async () => {
        const url = 'https://api.wsbbgames.com/app/api/spin/start';
        const data = {
            username: username,
            email: email,
        };
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(async data => {
                if (data.code === 200){
                    try{
                        let expected_result = data.data.result.split(",")
                        await spinReel(setReel1, expected_result[0]);
                        await spinReel(setReel2, expected_result[1]);
                        await spinReel(setReel3, expected_result[2]);
                        onFinished();
                    }catch (e) {
                        console.log(e)
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: `Spin is not ready yet!`,
                        });
                    }
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `${data.msg}!`,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Spin is not ready yet!`,
                });
            });
    };

    return (
        <div className="slot-machine">
            <div className="reel-container">
                <div className="reel" style={{ transform: `translateY(-${reel1 * 60}px)` }}>
                    {[...Array(31).keys()].map((num) => (
                        <div key={num}>{num < 10 ? `0${num}` : num}</div>
                    ))}
                </div>
            </div>
            <div className="reel-container">
                <div className="reel" style={{ transform: `translateY(-${reel2 * 60}px)` }}>
                    {[...Array(31).keys()].map((num) => (
                        <div key={num}>{num < 10 ? `0${num}` : num}</div>
                    ))}
                </div>
            </div>
            <div className="reel-container">
                <div className="reel" style={{ transform: `translateY(-${reel3 * 60}px)` }}>
                    {[...Array(31).keys()].map((num) => (
                        <div key={num}>{num < 10 ? `0${num}` : num}</div>
                    ))}
                </div>
            </div>
            <button id="spinButton" onClick={handleSpin}>Spin</button>
        </div>
    );
};

export default SlotMachine;
